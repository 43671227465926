import React from "react";
import PropTypes from "prop-types";
import Input from "../form/Input";
import Select from "../form/Select";
import { Editor } from 'react-draft-wysiwyg';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import isEmpty from "is-empty";


const ArticleForm = ({
   article,onChange,editorState,
   onEditorStateChange,list_cat,previewPicture,
   onBlur,isIntro, titleForm,
   onSubmit, isUpdate,boolTitle,
   boolTitleFunction,boolPic,boolPicFunction,
   picLoaded,imgLoading
}) => {
   const { category, title,picture, errors } = article;

   function validate_button(update) {

      switch (update) {
         case true:

         switch (imgLoading) {
            case true:
               
            return(<button className="button__rectangle__button button__rectangle__button--default button__rectangle__button--default--primary--icon">
                     <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                     Chargement de la photo...
                </button>)
         
            default:
               switch (boolPic) {
                  case true:
                     switch (imgLoading) {
                        case true:
                           
                        return(<button className="button__rectangle__button button__rectangle__button--default button__rectangle__button--default--primary--icon">
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                       Chargement de la photo...
                                 </button>)
                     
                        default:
                           return (<button onClick={onSubmit} className="button__rectangle__button button__rectangle__button--default button__rectangle__button--default--primary--icon">
                                    <span className="button__rectangle__button--default__icon">
                                    </span>
                                    Valider
                                 </button>)
                     }
                  default:
                     return (<div/>)
               }
         }
      
         default:

         switch (boolPic) {
            case true:
               switch (imgLoading) {
                  case true:
                     
                  return(<button className="button__rectangle__button button__rectangle__button--default button__rectangle__button--default--primary--icon">
                              <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                              Chargement de la photo...
                           </button>)
               
                  default:
                     return (<button onClick={onSubmit} className="button__rectangle__button button__rectangle__button--default button__rectangle__button--default--primary--icon">
                              <span className="button__rectangle__button--default__icon">
                              </span>
                              Valider
                           </button>)
               }
            default:
               return (<div/>)
         }
      }
     
   }

   // function display_pict(update) {
   //    switch (update) {
   //       case true:
            
   //          switch (!isEmpty(picLoaded)) {
   //             case true:
                  
   //                return(<div>
   //                   <div className="col-6"><p>Updated picture</p></div><div className="col-6"><img src={picture} alt="chosen" style={{height: '300px',paddingBottom:'20px'}} /></div>
   //                   </div>)
            
   //             default:
   //                return(<div>
   //                   <div className="col-6"><p>Actual picture</p></div><div className="col-6"><img src={picture} alt="chosen" style={{height: '300px',paddingBottom:'20px'}} /></div>
                  
   //                </div>)
   //          }
      
   //       default:  
   //          break;
   //    }
      
   // }

   function validate_pic(error) {
      switch (isEmpty(error)) {
         case true:
            
            return(<div className="button__rectangle">
            <button onClick={boolPicFunction} className="button__rectangle__button button__rectangle__button--default button__rectangle__button--default--primary--icon">
               <span className="button__rectangle__button--default__icon">
                  <img src="http://res.cloudinary.com/dhwx8elvg/image/upload/v1498800856/add-icon_sv5iwy.svg" alt="img for next button" />
               </span>
               Suivant
            </button>
         </div>)
      
         default:
            return(<h4 style={{color:'red'}}>⚠️ {error}</h4>)
      }
      
   }

   return (  
         <section className="section-padding">
            <div className="container">
               <div className="row">
                  <div className="col-md-10 col-lg-10 col-xs-10">
                  <div className="container">
                     <div className="stepper__row stepper__row--active">
                        <div className="stepper--vertical">
                           <div className="stepper--vertical__circle">
                           <span className="stepper--vertical__circle__text">
                              1
                           </span>
                           </div>
                           <div className="stepper--vertical__details">
                           <h4 className="heading__three">
                              Choisir un titre et une catégorie
                           </h4>
                           
                          
                           <Input
                              name="title"
                              type="text"
                              placeholder={title}
                              onChange={onChange}
                              onBlur={onBlur}
                              disabled={boolTitle}
                              text={{
                              module: "article",
                              label: "Titre",
                              error: errors.title
                              }}
                           />

                           <Select
                              name="category"
                              onChange={onChange}
                              text={category}
                              array={list_cat}
                              disabled={boolTitle}
                           />

                           <div onClick={boolTitleFunction} className="button__rectangle">
                              <button className="button__rectangle__button button__rectangle__button--default button__rectangle__button--default--primary--icon">
                                 <span className="button__rectangle__button--default__icon">
                                    <img src="http://res.cloudinary.com/dhwx8elvg/image/upload/v1498800856/add-icon_sv5iwy.svg" alt="img for next button"/>
                                 </span>
                                 Suivant
                              </button>
                           </div>
                           </div>
                        </div>
                     </div>
                     
                     <div className="stepper__row stepper__row--disabled">
                        <div className="stepper--vertical">
                           <div className="stepper--vertical__circle">
                           <span className="stepper--vertical__circle__text">
                              2
                           </span>
                           </div>
                           <div className="stepper--vertical__details">
                           <h3 className="heading__three">
                             Ajouter une image {"<"} <b>2 Mo</b>
                           </h3>
                           {isUpdate?
                              <div><div className="col-6"><p>Photo actuelle</p></div><div className="col-6"><img src={picture} alt="chosen" style={{height: '300px',paddingBottom:'20px'}} /></div></div>:<div/>}
                          

                           {/* {display_pict(isUpdate)} */}

                           {/* No picture */}

                           {boolPic?<div/>:<Input
                              name="picture"
                              type="file"
                              accept=".jpg, .png, .jpeg"
                              placeholder="Enter article picture"
                              onChange={onChange}
                              onBlur={onBlur}
                              disabled={!boolTitle}
                              text={{
                              module: "article",
                              label: "Image",
                              error: errors.picture
                              }}
                           />}

                           {isEmpty(errors.pic_size)?previewPicture && (
                              <div className="col-6">
                                 <p>Image mise à jour</p><img src={previewPicture} alt="chosen" style={{height: '300px',paddingBottom:'20px'}} />
                              </div>
                           ):<div/>}
                           {validate_pic(errors.pic_size)}
                           </div>
                        </div>
                     </div>
                     
                     
                     
                     <div className="stepper__row stepper__row--disabled">
                        <div className="stepper--vertical">
                           <div className="stepper--vertical__circle">
                           <span className="stepper--vertical__circle__text">
                              3
                           </span>
                           </div>
                           <div className="stepper--vertical__details">
                           <h3 className="heading__three">
                             Article
                           </h3>
                              <Editor
                                 name="body"         
                                 editorState={editorState}
                                 wrapperClassName="wrapper-class"
                                 editorClassName="editor-class"
                                 toolbarClassName="toolbar-class"
                                 wrapperStyle={{ border: "1px solid", marginBottom: "20px" }}
                                 editorStyle={{ height: "300px", padding: "1px"}}
                                 hashtag={{
                                    separator: ' ',
                                    trigger: '#',
                                  }}
                                
                                 onEditorStateChange={onEditorStateChange}
                                 onChange={onChange}
                                 readOnly={!boolPic}

                              />
                             
                              {validate_button(boolPic)}
                           </div>
                        </div>
                     </div>
                  </div>
                  </div>
               </div>
            </div>
         </section>
   );
};

ArticleForm.propTypes = {
   article: PropTypes.object.isRequired,
   loading: PropTypes.bool.isRequired,
   onBlur: PropTypes.func.isRequired,
   onChange: PropTypes.func.isRequired,
   onSubmit: PropTypes.func.isRequired
};

export default ArticleForm;