import React from "react";
import PropTypes from "prop-types";
import {Row, Col,Button } from "react-bootstrap";
import { Editor} from "draft-js";
import {category} from "../../utils/dashboardFunction";


const ViewArticle = ({ article, auth,onDelete, editorState,onEdit,add_like,disable_like}) => {

   return (
      <div>
         <br/>
         <br/>
      
      <div className="container">

      {auth && (
                  <Row className="d-flex">
                     <Col className='col-sm-3'>
                        <Button className="mb-3 text-center" onClick={onEdit}>
                           <span role='img' aria-label="update">🖋️ </span> Mettre à jour
                        </Button>
                     </Col>
                     <Col className='col-sm-6'></Col>
                     <Col className='col-sm-3'>
                        <Button className="mb-3 text-center" onClick={onDelete} >
                              <span role='img' aria-label="delete"> ❌ </span>  Supprimer
                        </Button>
                     </Col>
                     
                  </Row>
              
               )} 
         <div className="row">
            <div className="col-md-12 col-lg-12 col-xs-12">
            <div className="services-item wow fadeInDown" data-wow-delay="0.6s">
            
            <h1 className="page-title">{article.title}</h1>

            <br/>
            {category(article.category)}

            <div className="services-content">
               <h3><a>{article.category}</a></h3>
            </div>

            <img className="card-img center-block" src={article.picture} alt={article.title}/>
            
            <Editor
               name="body"
               // value={body}            
               editorState={editorState}
               wrapperClassName="wrapper-class"
               editorClassName="editor-class"
               toolbarClassName="toolbar-class"
               wrapperStyle={{ border: "1px solid", marginBottom: "20px" }}
               editorStyle={{ height: "300px", padding: "10px"}}
               // onEditorStateChange={onEditorStateChange}
               // onChange={onChange}
            />

            <Row className="d-flex" style={{fontSize:"medium"}}>
               <Col className='col-sm-2'>{article.date} {article.author}</Col>
               <Col className='col-sm-8'></Col>
               {disable_like?<Col className='col-sm-2'> <a onClick={add_like} > 🖤 </a> Merci ! </Col>:<Col className='col-sm-2'> <a onClick={add_like} > <span role='img' aria-label="thumbs">👍🏾</span> </a>{article.nb_like} </Col>}
            </Row>
           
         
            </div>
         </div>    
      </div>
   </div>

   <br/>
   <br/>
   </div>

   );
};

ViewArticle.propTypes = {
   article: PropTypes.object.isRequired,
   auth: PropTypes.bool.isRequired,
   onEdit: PropTypes.func.isRequired,
   onDelete: PropTypes.func.isRequired
};

export default ViewArticle;