import { combineReducers } from "redux";
import errorReducer from "./errorReducers";
import articleReducer from "./articleReducers";
import authReducer from "./authReducers";
import toggleSubmit from "./messageReducers";

export default combineReducers({
   errors: errorReducer,
   article: articleReducer,
   auth: authReducer,
   toggle: toggleSubmit
});