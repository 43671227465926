import React from "react";

export function currencyFormat(number){
        const thisNumber = number/100
        return Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(thisNumber)
    }
export function category(cat) {

    switch (cat) {
       case 'Informatique':
          return (<div className="icon">
                      <i className="icon-screen-desktop" style={{color:"pink"}}></i>
                   </div>)

       case "Finance":
          return    <div className="icon">
          <i className="icon-wallet" style={{color:"green"}}></i>
       </div>
    
       default:
          return    <div className="icon">
          <i className="icon-bubble"style={{color:"black"}}></i>
       </div>
    
    }
    
 }
