import React from 'react';
import {useLocation} from "react-router-dom";

const Header = ({
  isAuth,
  logout
}) => {
  let loc =useLocation()

  function heroArea(path) {

    if (path.includes('/blog')) {

      return<div id="hero-area" className="hero-area-bg">
              <div className="overlay"></div>
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-sm-12 text-center">
                    <div className="contents">
                      <h3 className="head-title wow fadeInUp" data-wow-delaydata-wow-delay="0.2s">Blog</h3>
                      <h1 className="script-font wow fadeInUp" data-wow-delay="0.4s">Finance <span role='img' aria-label="finance">📈 </span>et Programmation informatique <span role='img' aria-label="geek">👩🏾‍💻</span></h1>
                      {/* <p className="script-font wow fadeInUp" data-wow-delay="0.6s">présentation courte </p> */}
                      <ul className="social-icon wow fadeInUp" data-wow-delay="0.8s">
                        <li>
                          <a className="github" href="https://github.com/NadNadou"><i className="icon-social-github"></i></a>
                        </li>
                        <li>
                          <a className="linkedin" href="https://www.linkedin.com/in/nadia-abdallah-581a2048/"><i className="icon-social-linkedin"></i></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      
    } else {

     if (path.includes('/dashboard')) {
      return<div id="hero-area" className="hero-area-bg">
              <div className="overlay"></div>
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-sm-12 text-center">
                    <div className="contents">
                      <h3 className="head-title wow fadeInUp" data-wow-delaydata-wow-delay="0.2s">Tableau de bord</h3>
                      <h1 className="script-font wow fadeInUp" data-wow-delay="0.4s">Gestion des articles et indicateurs clés</h1>
                      {/* <p className="script-font wow fadeInUp" data-wow-delay="0.6s">présentation courte </p> */}
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>

       
     } else {
       return <div id="hero-area" className="hero-area-bg">
       <div className="overlay"></div>
       <div className="container">
         <div className="row">
           <div className="col-md-12 col-sm-12 text-center">
             <div className="contents">
               <h1 className="script-font wow fadeInUp" data-wow-delaydata-wow-delay="0.2s">Bonjour je suis</h1>
               <h2 className="head-title wow fadeInUp" data-wow-delay="0.4s">Nadia</h2>
               <p className="script-font wow fadeInUp" data-wow-delay="0.6s">Full stack developpeur et experte en VBA</p>
               <ul className="social-icon wow fadeInUp" data-wow-delay="0.8s">
                 <li>
                   <a className="github" href="https://github.com/NadNadou"><i className="icon-social-github"></i></a>
                 </li>
                 <li>
                   <a className="linkedin" href="https://www.linkedin.com/in/nadia-abdallah-581a2048/"><i className="icon-social-linkedin"></i></a>
                 </li>
               </ul>
             </div>
           </div>
         </div>
       </div>
     </div>
       
     }
      
    }
    
  }
  return (
  <header id="header-wrap">
  {/* <!-- Navbar Start --> */}
  <nav className="navbar navbar-expand-lg fixed-top scrolling-navbar indigo">
    <div className="container">
      {/* <!-- Brand and toggle get grouped for better mobile display --> */}
      <div className="navbar-header">
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#main-navbar" aria-controls="main-navbar" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
          <span className="icon-menu"></span>
          <span className="icon-menu"></span>
          <span className="icon-menu"></span>
        </button>
        <a href="/" className="navbar-brand"><img src="img/logo.png" alt=""/></a>
      </div>
      <div className="collapse navbar-collapse" id="main-navbar">
        <ul className="onepage-nev navbar-nav mr-auto w-100 justify-content-end clearfix">
          <li className="nav-item active">
          <a className="nav-link" href="/"> Accueil </a>
          
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/#about">A propos</a>
          </li>
          <li className="nav-item">
           <a className="nav-link" href="/#services">Compétences</a>
          </li>
        
          <li className="nav-item">
          <a className="nav-link" href="/#portfolios">Projets</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" style={{color:'#d685be'}} href="/blog">
             Blog
            </a>
          </li>
          <li className="nav-item">
           {isAuth?<a className="nav-link" style={{color:'blue'}} href="/dashboard">
             Dashboard
            </a>: <div/>}
          </li>
          <li className="nav-item">
           {isAuth?<a href='/' className="nav-link icon-logout" style={{color:'blue'}} onClick={()=>logout()}>
             
            </a>: <div/>}
          </li>
        </ul>
      </div>
    </div>

    {/* <!-- Mobile Menu Start --> */}
    <ul className="onepage-nev mobile-menu">

    
      <li>
        <a href="/">Accueil</a>
      </li>
      <li>

        <a className="nav-link" href="/#services">A propos</a>
      </li>
      <li>
        <a href="/#services">Compétences</a>
      </li>
     
      <li>
        <a href="/#portfolio">Compétences</a>
      </li>
      <li>
        <a href="/blog">Blog</a>
      </li>
      {/* <li>
        <a href="#contact">Contact</a>
      </li> */}
    </ul>
    {/* <!-- Mobile Menu End --> */}
  </nav>
  {/* <!-- Navbar End --> */}

  {/* <!-- Hero Area Start --> */}

  {heroArea(loc.pathname)}
  {/* {loc.pathname.includes('/blog') || loc.pathname.includes('/dashboard') ?<div/>:<div id="hero-area" className="hero-area-bg">
    <div className="overlay"></div>
    <div className="container">
      <div className="row">
        <div className="col-md-12 col-sm-12 text-center">
          <div className="contents">
            <h1 className="script-font wow fadeInUp" data-wow-delaydata-wow-delay="0.2s">Bonjour je suis</h1>
            <h2 className="head-title wow fadeInUp" data-wow-delay="0.4s">Nadia</h2>
            <p className="script-font wow fadeInUp" data-wow-delay="0.6s">Full stack developpeur et experte en VBA</p>
            <ul className="social-icon wow fadeInUp" data-wow-delay="0.8s">
              <li>
                <a className="github" href="https://github.com/NadNadou"><i className="icon-social-github"></i></a>
              </li>
              <li>
                <a className="linkedin" href="https://www.linkedin.com/in/nadia-abdallah-581a2048/"><i className="icon-social-linkedin"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>} */}
  
  {/* <!-- Hero Area End --> */}

</header>
  
    
)};

export default Header;