const PATTERN = "^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+).([a-zA-Z]{2,5})$";

const Validate = (name, value) => {
   let errors = {};
   switch (name) {
      case "user_name":
         errors.user_name = value.length === 0 ? "Le nom d'utilisateur est nécessaire" : "";
         break;

      case "email":
         errors.email =
            value.length === 0
               ? "Une adresse mail est nécessaire"
               : !value.match(PATTERN)
               ? "Le mail renseigné n'est pas au bon format"
               : "";
         break;

      case "password":
         errors.password =
            value.length === 0
               ? "Un mot de passe est requis"
               : value.length < 6
               ? "Le mot de passe doit contenir au moins 6 caractères"
               : "";
         break;
         
      default:
         break;
   }

   return {
      errors
   };
};

export default Validate;