import axios from "axios";
import {
   CREATE_ARTICLE,
   UPLOAD_IMAGE,
   GET_IMG_CLOUDINARY,
   GET_ARTICLE,
   GET_ARTICLES,
   UPDATE_ARTICLE,
   DELETE_ARTICLE,
   TOGGLE_ARTICLES_LOADING,
   TOGGLE_ARTICLE_LOADING,
   TOGGLE_IMG_LOADING,
   RESET_ARTICLE,
   DELETE_IMG,
   ADD_LIKE,
   TOGGLE_LIKE_ADDING
} from "./types";

import { setErrors, clearErrors } from "./errorActions";

let axiosConfig = {
   headers: {
       'Content-Type': 'application/json;charset=UTF-8',
       "Access-Control-Allow-Origin": "*",
   }
 };

export const createArticle = (articleData,history) => dispatch => {
   dispatch(toggleArticleLoading());
   axios
      .post("/api/articles/create",articleData,axiosConfig)
      .then(res => {
         dispatch({
            type: CREATE_ARTICLE,
            payload: res.data
         });
         dispatch(toggleArticleLoading());

         history.push("/dashboard");
      })
      .catch(err => {
         dispatch(setErrors(err));
         dispatch(toggleArticleLoading());
         console.log('erreur : ',err)
      });
};

export const uploadImage =(base64EncodedImage)=>dispatch=>{
   dispatch(toggleImgLoading());

   axios
      .post('/api/articles/image/upload',{data:base64EncodedImage})
      .then(res => {
         dispatch({
            type: UPLOAD_IMAGE,
            payload:res.data
         })
         dispatch(toggleImgLoading());
      })
      .catch(err => {
         dispatch(setErrors(err));
         dispatch(toggleImgLoading());
      });
}

//get all images
export const getImagesCloudinary =()=>dispatch=>{
   dispatch(toggleArticleLoading());
   axios
      .get('/api/articles/fetch/images')
      .then(res => {
         dispatch({
            type: GET_IMG_CLOUDINARY,
            payload:res.data
         })
         dispatch(toggleArticleLoading());
      
      })
      .catch(err => {
         dispatch(setErrors(err));
         dispatch(toggleArticleLoading());
      });
}
//Public path
export const getAllArticles = () => dispatch => {
   axios
      .get("/api/articles/all")
      .then(res => {
         dispatch({
            type: GET_ARTICLES,
            payload: res.data
         });
         dispatch(clearErrors());

      })
      .catch(err => {
         dispatch(setErrors(err));

      });
};

//add like to an article
export const addLike = (id,author,adding_like) => dispatch => {
   dispatch(toggleAddLikeLoading());
   axios
      .patch(`/api/articles/addLike`,{id:id,author:author,nb_like:adding_like})
      .then(res => {
         dispatch({
            type: ADD_LIKE,
            payload: res.data
         });
         dispatch(clearErrors());
         dispatch(toggleAddLikeLoading());


      })
      .catch(err => {
         dispatch(setErrors(err));
         dispatch(toggleAddLikeLoading());

      });
};


export const getArticleByID = id => dispatch => {
   dispatch(toggleArticleLoading());
   axios
      .get(`/api/articles/article/${id}`)
      .then(res => {
         dispatch({
            type: GET_ARTICLE,
            payload: res.data
         });
         dispatch(clearErrors());
         dispatch(toggleArticleLoading());
      })

      .catch(err => {
         dispatch(setErrors(err));
         dispatch(toggleArticleLoading());
      });
};

export const getArticleByLinkFormat = linkFormat => dispatch => {
   dispatch(toggleArticleLoading());
   axios
      .get(`/api/articles/article/link/${linkFormat}`)
      .then(res => {
         dispatch({
            type: GET_ARTICLE,
            payload: res.data
         });
         dispatch(clearErrors());
         dispatch(toggleArticleLoading());
      })

      .catch(err => {
         dispatch(setErrors(err));
         dispatch(toggleArticleLoading());
      });
};

export const getArticles = () => dispatch => {
   dispatch(toggleArticlesLoading());
   axios
      .get(`/api/articles/`)
      .then(res => {
         dispatch({
            type: GET_ARTICLES,
            payload: res.data
         });
         dispatch(clearErrors());
         dispatch(toggleArticlesLoading());
      })
      .catch(err => {
         dispatch(setErrors(err.response.data));
         dispatch(toggleArticlesLoading());
      });
};

export const getArticlesByAuthor = author => dispatch => {
   dispatch(toggleArticlesLoading());
   axios
      .get(`/api/articles/author/${author}`)
      .then(res => {
         dispatch({
            type: GET_ARTICLES,
            payload: res.data
         });
         dispatch(toggleArticlesLoading());
      })
      .catch(err => {
         dispatch(setErrors(err.response.data));
         dispatch(toggleArticlesLoading());
      });
};

export const updateArticle = (id, articleData, history) => dispatch => {
   dispatch(toggleArticleLoading());
   axios
      .patch(`/api/articles/update/${id}`, articleData)
      .then(res => {
         dispatch({
            type: UPDATE_ARTICLE,
            payload: res.data
         });

         dispatch(toggleArticleLoading());
         history.push(`/dashboard`)
      })
      .catch(err => {
         dispatch(setErrors(err.response.data));
         dispatch(toggleArticleLoading());
      });
};

export const deleteImgCloud=(public_id) => dispatch=>{
   dispatch(toggleArticleLoading());
   axios
      .delete(`/api/articles/img/delete`, {
         params: public_id
         })
      .then(res => {
         dispatch({
            type: DELETE_IMG,
            payload: res.data
         });
         dispatch(toggleArticleLoading());
         // history.push("/dashboard");
        
      })
      .catch(err => {
         dispatch(setErrors(err));
         dispatch(toggleArticleLoading());
      });
};

export const deleteArticle = (id,history) => dispatch => {
   dispatch(toggleArticleLoading());
   axios
      .delete(`/api/articles/delete/${id}`)
      .then(res => {
         dispatch({
            type: DELETE_ARTICLE,
            payload: id
         });
         dispatch(toggleArticleLoading());
         history.push("/dashboard");
      })
      .catch(err => {
         dispatch(setErrors(err.response.data));
         dispatch(toggleArticleLoading());
      });
};

export const resetArticle = () => {
   return {
      type: RESET_ARTICLE
   };
};

export const toggleArticleLoading = () => {
   return {
      type: TOGGLE_ARTICLE_LOADING
   };
};

export const toggleAddLikeLoading=()=> {
   return {
      type: TOGGLE_LIKE_ADDING
   }
}

export const toggleArticlesLoading = () => {
   return {
      type: TOGGLE_ARTICLES_LOADING
   };
};

export const toggleImgLoading = () => {
   return {
      type: TOGGLE_IMG_LOADING
   };
};