import React from "react";
import {Card} from "react-bootstrap";
import {category} from "../../utils/dashboardFunction";

const Article = ({article,add_like})  => {

   const find_body_text = Object.values(JSON.parse(article.body).blocks).map(a=>a.text)
   // const article_text = EditorState.createWithContent(convertFromRaw(JSON.parse(article.body)))
   const overview = (find_body_text.join(' ')).substring(0,500)+' ...'

  return (

  <Card className="deckStyle" style={{ border: "none", backgroundColor:'#fff'}}>
      <div className="services-item wow fadeInDown" data-wow-delay="0.6s">
     {category(article.category)}
      <h5 style={{color:'black'}}>{article.category}</h5>
      <div className="services-content">
         <h3><a href={`/blog/${article.linkFormat}`}>{article.title}</a></h3>
         {!article?<div/>:<p style={{textAlign:"justify",color:"black"}} >{overview}</p>}
      </div>
      </div>

      <div className="card-footer" style={{backgroundColor:"#333332"}}>
         <p className="card-text" style={{color:"white"}}>
            {article.date} - {article.author} - <span role='img' aria-label="thumbs"> 👍🏾 </span> {article.nb_like}
            
         </p>

      </div>
   </Card>

    );
};

export default Article; 

