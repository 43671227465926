// import { createStore, applyMiddleware,compose} from "redux";
import { createStore, applyMiddleware} from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

// const middleware = [thunk];
// const store = createStore(
//    rootReducer,
//    compose(applyMiddleware(...middleware), window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),)
//    );
// export default store;

const initialState = {};
const middleware = [thunk];

const store = createStore(
   rootReducer,
   initialState,
   applyMiddleware(...middleware)
);

export default store;