import React, { useState } from "react";
import Modal from 'react-modal';

const Porfolios = ()  => {
   let subtitle;
   const [modalIsOpen,setIsOpen] = useState(false);
   const [modalIsOpen2,setIsOpen2] = useState(false);
   const [modalIsOpen3,setIsOpen3] = useState(false);

   const customStyles = {
      content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
      }
    };
    
    // Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
    Modal.setAppElement('#root')

//First modal
   const openModal1 = ()=> {
     setIsOpen(true);
   }
   const afterOpenModal1 = () => {
     subtitle.style.color = '#015A99';
   }
   const closeModal1= () => {
     setIsOpen(false);
   }

//Second modal
   const openModal2 = ()=> {
      setIsOpen2(true);
   }
   const afterOpenModal2 = () => {
      subtitle.style.color = '#015A99';
   }
   const closeModal2= () => {
      setIsOpen2(false);
   }

//Second modal
   const openModal3 = ()=> {
      setIsOpen3(true);
   }
   const afterOpenModal3 = () => {
      subtitle.style.color = '#015A99';
   }
   const closeModal3= () => {
      setIsOpen3(false);
   }
 
  return (
    <section id="portfolios" className="section-padding">
    {/* <!-- Container Starts --> */}
    <div className="container">        
      <h1 className="section-title wow flipInX" data-wow-delay="0.4s">Mes principales réalisations</h1>
      <div className="row">          
        <div className="col-md-12">
        </div>
  
        {/* <!-- Portfolio Recent Projects --> */}
        <div id="portfolio" className="row wow fadeInDown" data-wow-delay="0.4s">
          <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mix development">

         {/* START --  modal */}
            <div className="btn btn-primary" onClick={openModal1}>Info</div>
            <pre></pre>
         
            <Modal
               isOpen={modalIsOpen}
               onAfterOpen={afterOpenModal1}
               onRequestClose={closeModal1}
               style={customStyles}
               contentLabel="Spendash project"
            >
               <div className="btn btn-dark btn-sm  pull-right" onClick={closeModal1}>Fermer</div>
               <h3 ref={_subtitle => (subtitle = _subtitle)}>Spendash - projet MERN</h3>
               <div>
                  <p>Spendash est un projet MERN (MongoDB Express React JS et Node JS)</p>

                  <pre></pre>

                  <p>
                     <b>Projet</b> : outil de gestion des finances personnelle et aide à la prise de décision.
                     <p>Cet outil permet de visualiser le flux des revenus et dépenses au fil du mois et de budgéter</p>
                     <p>les dépenses à venir.Il contient également une partie blog.</p>
                  </p>

                  <pre></pre>

                  <b>Description technique </b>: le projet m'a permis de développer mes compétences en gestion de base de données
                  <p>avec MongoDB, gérer la partie Back-end d'un projet avec Express et</p>
                  <p> nodeJS et enfin développer mes compétences en front-end avec ReactJS.</p>


                  <pre></pre>
                 <b>Lien : </b> <a className="link" href="https://spendash.com/">Spendash</a>
               </div>

            </Modal>

            {/* END --  modal */}
  
            <div className="portfolio-item">
              <div className="shot-item">
  
                <img src="img/gallery/spendash-gif.gif" alt="Spendash website" /> 
                <div className="overlay">
                  <div className="icons">
                    <a className="link" href="https://spendash.com/">
                      <i className="icon-link"></i>
                    </a>
                  </div>
                </div>
              </div>               
            </div>
          </div>
  
          <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mix development">
         {/* START --  modal */}
            <div className="btn btn-primary" onClick={openModal2}>Info</div>
            <pre></pre>
         
            <Modal
               isOpen={modalIsOpen2}
               onAfterOpen={afterOpenModal2}
               onRequestClose={closeModal2}
               style={customStyles}
               contentLabel="Kwiga project"
            >
               <div className="btn btn-dark btn-sm  pull-right" onClick={closeModal2}>Fermer</div>
               <h3 ref={_subtitle => (subtitle = _subtitle)}>Kwiga - project module de paiement</h3>
               <div>
                  <p>Kwiga est un projet avec implémentation d'un module de paiement - Stripe</p>

                  <pre></pre>

                  <p>
                     <b>Projet</b> : site vitrine pour la vente de cours en ligne spécialisé en Excel et la suite Microsoft
                     <p>Kwiga est un site dédié aux étudiants désireux de monter en compétence rapidement en Excel et VBA</p>
                  </p>

                  <pre></pre>

                  <b>Description technique </b>: le projet m'a permis d'apprendre à implémenter le module de paiement Stripe ainsi que l'utilisation de Sendgrid.
                  <p><a className="link" href="https://stripe.com/fr">Stripe </a>est une infrastructure de paiement pour le commerce en ligne. Il permet également la mise en place de</p>
                  <p>d'une souscription et ce tout en garantissant une sécurité pour le commerçant et le client.</p><a className="link" href="https://sendgrid.com/">Sendgrid </a>est quant à elle une plate-forme de communication pour le courrier électronique transactionnel et marketing


                  <pre></pre>
                 <b>Lien : </b> <a className="link" href="https://www.kwiga.dev/">Kwiga</a>
               </div>

            </Modal>

            {/* END --  modal */}
            <div className="portfolio-item">
              <div className="shot-item">
                <img src="img/gallery/kwiga-gif.gif" alt="Kwiga website" /> 
                <div className="overlay">
                  <div className="icons">
                    <a className="link" href="https://www.kwiga.dev/">
                      <i className="icon-link"></i>
                    </a>
                  </div>
                </div>
              </div>               
            </div>
          </div>
  
          <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mix other design">
            <div className="portfolio-item">
            
            {/* START --  modal */}
            <div className="btn btn-primary" onClick={openModal3}>Info</div>
            <pre></pre>
         
            <Modal
               isOpen={modalIsOpen3}
               onAfterOpen={afterOpenModal3}
               onRequestClose={closeModal3}
               style={customStyles}
               contentLabel="Usouka"
            >
               <div className="btn btn-dark btn-sm  pull-right" onClick={closeModal2}>Fermer</div>
               <h3 ref={_subtitle => (subtitle = _subtitle)}>Usouka - plateforme de coiffure</h3>
               <div>
                  <p>Usouka est une plateforme qui permet de connecter les coiffeuses afros et celles qui souhaitent prendre soin de leur chevelure.</p>

                  <pre></pre>

                  <p>
                     <b>Projet</b> : plateforme de connexion entre des coiffeuses et des clientes. Cette plateforme est dédiée aux femmes avec des cheveux "texturés"
                     <p>Usouka est un projet qui répond au besoin des femmes aux cheveux "texturés" qui souhaitent confier leur chevelure à des femmes qui pourront les sublimer.</p>
                  </p>

                  <pre></pre>

                  <b>Description technique </b>: le projet m'a permis d'apprendre à implémenter le module de paiement Stripe, l'utilisation de Sengrid, la réservation de créneaux horaires ainsi que de travailler mes compétences en SEO.
                  <p><a className="link" href="https://stripe.com/fr">Stripe </a>est une infrastructure de paiement pour le commerce en ligne. Il permet également la mise en place de</p>
                  <p>d'une souscription et ce tout en garantissant une sécurité pour le commerçant et le client.</p><a className="link" href="https://sendgrid.com/">Sendgrid </a>est quant à elle une plate-forme de communication pour le courrier électronique transactionnel et marketing


                  <pre></pre>
                 <b>Lien : </b> <a className="link" href="https://www.usouka.com/">Usouka</a>
               </div>
            </Modal>

            {/* END --  modal */}
              <div className="shot-item">
                <img src="img/gallery/usouka-gif.gif" alt="Usouka"/>
                <div className="overlay">
                  <div className="icons">
                    {/* <a className="lightbox preview" href="img/gallery/excel-portfolio.jpg">
                      <i className="icon-info"></i>
                    </a> */}
                    <a className="link" href="test">
                      <i className="icon-link"></i>
                    </a>
                  </div>
                </div>
              </div>               
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* <!-- Container Ends --> */}
  </section>
  );
};

export default Porfolios;