import React from 'react';
import { BrowserRouter, Route, Switch,Redirect} from "react-router-dom";
import {Provider} from "react-redux";
import store from "./store";

import jwt_decode from 'jwt-decode';
import setAuthToken from './utils/setAuthToken';
import { setCurrentAdmin,logoutAdmin } from './actions/authActions';

import PrivateRoute from "./utils/PrivateRoute";

import Portfolio from "./components/layout/Global";
import BlogPage from "./containers/blog/BlogPage"; 

import LoginPage from "./containers/auth/LoginPage";
import DashboardPage from "./containers/admin/DashboardPage";
import CreateArticlePage from "./containers/blog/CreateArticlePage";
import ViewArticleAdminPage from "./containers/admin/ViewArticleAdminPage";
import ViewArticlePage from "./containers/blog/ViewArticlePage";
import UpdateArticlePage from "./containers/admin/UpdateArticlePage";

import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";


if (localStorage.jwtToken) {
  const token = localStorage.jwtToken;
  setAuthToken(token);
  const decoded = jwt_decode(token);
  store.dispatch(setCurrentAdmin(decoded));
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
     store.dispatch(logoutAdmin());
     window.location.href = "./login";
  }
}

export const logoutFunction = () => {
  store.dispatch(logoutAdmin())
};

const App = () => {
  return (
      <Provider store={store}>
        <BrowserRouter>
          <Header
            isAuth={store.getState().auth.isAuthenticated}
            logout={logoutFunction} />
            
             <Switch>
                 <Route path="/" exact component={Portfolio} />
                 <Route path='/blog' exact component={BlogPage} />
                 <Route path="/blog/:linkFormat" exact component={ViewArticlePage} />

                 <Route path='/login' exact component={LoginPage} />
                 <PrivateRoute exact path='/dashboard' component={DashboardPage} />
                 <PrivateRoute exact path='/dashboard/create' component={CreateArticlePage} />
                 <PrivateRoute exact path='/dashboard/article/:id' component={ViewArticleAdminPage} />
                 <PrivateRoute exact path='/dashboard/article/update/:id' component={UpdateArticlePage} />
                 
                 <Redirect from="*" to="/" />
              </Switch>
              <Footer/>
        </BrowserRouter>
      </Provider>

  );
};

export default App;
