import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import About from "./About";
import Goto from "./GoTo";
import Portfolios from "./Portfolios";
import Services from "./Services";

import {logoutAdmin} from "../../actions/authActions";

const Global = ({}) => {
    return (

    <div>
        <About/>
        <Services/>
        <Portfolios/> 
        <Goto/>
    </div>)
};

Global.propTypes = {
    logoutAdmin:PropTypes.func.isRequired
   
 };
 
 const mapStateToProps = state => ({
    isAuthenticated:state.auth.isAuthenticated
   
 });
 
 export default connect(
    mapStateToProps,
    {logoutAdmin}
 )(Global)