import React, { useEffect, useState} from "react";
import PropTypes from "prop-types";
import ViewArticle from "../../components/admin/ViewArticle";
import {getArticleByLinkFormat,addLike} from "../../actions/articleActions";
import { EditorState, ContentState, convertFromRaw} from 'draft-js';
import {connect} from "react-redux";

import {useLocation} from "react-router-dom"

const ViewArticlePage = ({
   auth,
   article,
   match,
   getArticleByLinkFormat,
   addLike,
   addLikeLoading
}) => {

   const [isLiked, setIsLiked] = useState(false)

   useEffect(() => {
      getArticleByLinkFormat(match.params.linkFormat)
   }, [match, getArticleByLinkFormat,addLikeLoading]);

   const len_articles = [...Array(Object.keys(article).length).keys()]
   const view_article = len_articles.map(a=>Object.values(article)[a])
   const article_text = !view_article[4]?EditorState.createWithContent(ContentState.createFromText('Article')):EditorState.createWithContent(convertFromRaw(JSON.parse(view_article[4])))
   
   let location = useLocation();
   if (Object.keys(article).length === 0) return <div />;


   const handleAddLike = ()=> {
   let adding_like = article.nb_like+1

    addLike(article._id,article.author,adding_like)

    setIsLiked(!isLiked)
   };


   return (

         <ViewArticle
            article={article}
            auth={auth && !location.pathname.includes("blog")}
            editorState={article_text}

            //likes
            add_like={handleAddLike}
            disable_like={isLiked}
         />

   );
};
const mapStateToProps = state => ({
   article: state.article.article,
   auth:state.auth.isAuthenticated,
   addLikeLoading:state.article.addLikeLoading
});
ViewArticlePage.propTypes = {
   auth:PropTypes.bool.isRequired,
   article: PropTypes.object.isRequired,
   getArticleByLinkFormat: PropTypes.func.isRequired,
   addLike:PropTypes.func.isRequired
};

export default connect(
   mapStateToProps,
   {getArticleByLinkFormat,addLike}
)(ViewArticlePage);