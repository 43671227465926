import React from 'react';

export const actualYear = (date) => {
  return date.year();
}

const About = () => (
  <section id="about" className="section-padding">
  <div className="container">
    <div className="row">
      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div className="img-thumb wow fadeInLeft" data-wow-delay="0.3s">
          <img className="img-fluid" src="img/about/about-1.jpg" alt=""/>
        </div>
      </div> 
      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div className="profile-wrapper wow fadeInRight" data-wow-delay="0.3s">
          <h1>Bonjour à vous <span role='img' aria-label="hello">👋🏾</span></h1>
          <p>Après mon Master en Audit/ Contrôle de gestion obtenu à la SKEMA Business School, j'ai débuté une licence d'informatique avec le CNAM Paris (Conservatoire National des Arts et des Métiers) en cours du soir. J'occupe à présent le poste de consultante EPM et j'interviens principalement sur des sujets liés à la Supply Chain. En parallèle, je développe mes compétences en programmation informatique. Celles-ci concernent principalement le Full Stack sur Javascript et Excel/VBA au niveau de la bureautique.</p>
          <div className="about-profile">
            <ul className="admin-profile">
              <li><span className="pro-title"> Prénom </span> <span className="pro-detail">Nadia</span></li>
              {/* <li><span className="pro-title"> Experience </span> <span className="pro-detail">{actualYear(moment())-2015} ans</span></li> */}
              <li><span className="pro-title"> Pays </span> <span className="pro-detail">France</span></li>
              <li><span className="pro-title"> Ville </span> <span className="pro-detail">Paris</span></li>
              <li><span className="pro-title"> Mail </span> <span className="pro-detail">nadia.abdallah@skema.edu</span></li>
            </ul>
          </div>
          {/* <a href="#" className="btn btn-common"><i className="icon-paper-clip"></i>Télécharger mon CV</a> */}
            <a href="mailto:nadia.abdallah@skema.edu" className="btn btn-danger text-centered">
              <i className="icon-envelope-open"></i> Me contacter
            </a>
        </div>
      </div>  
       
    </div>

  </div>
</section>
    
);

export default About;