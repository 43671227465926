import React, {useEffect } from "react";
import BlogListArticle from "../../components/blog/BlogListArticle"
import {getAllArticles} from "../../actions/articleActions";
import { connect } from "react-redux";
import PropTypes from "prop-types";


const BlogPage = ({
   
   getAllArticles,
   articles,

}) => {


   useEffect(() => {
      getAllArticles();
   }, [getAllArticles]);


   return (
    
          <BlogListArticle
            articles={articles}
         />

   )
};

BlogPage.propTypes = {
   articles: PropTypes.array.isRequired,
   getAllArticles: PropTypes.func.isRequired
  
};

const mapStateToProps = state => ({
   articles: state.article.articles
  
});


export default connect(
   mapStateToProps,
   {getAllArticles}
 )(BlogPage);