import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";

import { SET_CURRENT_ADMIN, TOGGLE_ADMIN_LOADING,UPDATE_CLIENT } from "./types";
import { resetArticle } from "./articleActions";
import { setErrors } from "./errorActions";

export const registerAdmin = (clientData, history) => dispatch => {
   dispatch(toggleAdminLoading());
   axios
      .post("/api/users/signup", clientData)
      .then(res => {
         dispatch(toggleAdminLoading());
         localStorage.setItem(
            "loginMessage",
            "Inscription validée"
         );
         history.push("/login");
      })
      .catch(err => {
         dispatch(setErrors(err.response.data));
         dispatch(toggleAdminLoading());
      });
};

export const loginClient = clientData => dispatch => {
   dispatch(toggleAdminLoading());
   axios
      .post("/api/users/login", clientData)
      .then(res => {
         dispatch(resetArticle());
         const { token } = res.data;
         localStorage.setItem("jwtToken", token);
         setAuthToken(token);
         const decoded = jwt_decode(token);
         dispatch(setCurrentAdmin(decoded));
         dispatch(toggleAdminLoading());
      })
      .catch(err => {
         dispatch(setErrors(err));
         dispatch(toggleAdminLoading());
      });
};

export const updateClient = (clientData,user_name,email,pwd) => dispatch => {
   dispatch(toggleAdminLoading());
   axios
      .patch(`/api/users/updateProfil/${user_name}/${email}/${pwd}`,clientData)
      .then(res => {
         dispatch({
            type: UPDATE_CLIENT,
            payload: res.data
         });
         dispatch(toggleAdminLoading());
         window.location.reload();
      })
      .catch(err => {
         dispatch(setErrors(err.response.data));
         dispatch(toggleAdminLoading());
      });
};

export const setCurrentAdmin = clientData => {
   return {
      type: SET_CURRENT_ADMIN,
      payload: clientData
   };
};

export const toggleAdminLoading = () => {
   return {
      type: TOGGLE_ADMIN_LOADING
   };
};

export const logoutAdmin = () => dispatch => {
   localStorage.removeItem("jwtToken");
   setAuthToken(false);
   dispatch(setCurrentAdmin({}));
   window.location.reload();
};