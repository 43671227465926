import React, {useEffect } from "react";
import {getAllArticles,getImagesCloudinary} from "../../actions/articleActions";
import Dashboard from "../../components/admin/Dashboard";


import { connect } from "react-redux";
import PropTypes from "prop-types";


const DashboardPage = ({ 
    getAllArticles,
    getImagesCloudinary,
    img_cloudinary,
    articles,
    auth }) => {

  useEffect(() => {
    getAllArticles();
    getImagesCloudinary()
 }, [getAllArticles,getImagesCloudinary]);

 const len_img = [...Array(Object.keys(img_cloudinary['result']).length).keys()]
 const img_tab = len_img.map(a => Object.values(img_cloudinary)[0][a]['public_id'])


//  const len_articles = [...Array(Object.keys(articles).length).keys()]
 // const intro_article = len_article.map(a=>Object.values(articles)[a]).filter(b=>b.category == 'Introduction')
 // const intro_text = !intro_article[0]?EditorState.createWithContent(ContentState.createFromText('Introduction')):EditorState.createWithContent(convertFromRaw(JSON.parse(intro_article[0]["body"])))
const no_introduction = articles.filter(a=>a.category !=="Introduction")


   return (
      <Dashboard 
        articles={no_introduction}
        auth={auth}
        list_img={img_tab}
      />
   );
};

DashboardPage.propTypes = {
   articles: PropTypes.array.isRequired,
   getAllArticles: PropTypes.func.isRequired,
   auth: PropTypes.bool.isRequired,
 };

 const mapStateToProps = state => ({
   articles: state.article.articles,
   auth: state.auth.isAuthenticated,
   img_cloudinary:state.article.img_cloudinary
 });
 
 export default connect(
   mapStateToProps,
   {getAllArticles,getImagesCloudinary}
 )(DashboardPage);