import React from 'react';

const Footer = () => (
   //Footer Section Start
    <footer className="footer-area section-padding">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="footer-text text-center wow fadeInDown" data-wow-delay="0.3s">
              <ul className="social-icon">
                <li>
                  <a className="github" href="https://github.com/NadNadou"><i className="icon-social-github"></i></a>
                </li>
                <li>
                  <a className="linkedin" href="https://www.linkedin.com/in/nadia-abdallah-581a2048/"><i className="icon-social-linkedin"></i></a>
                </li>
                <li>
                  <a href="mailto:nadia.abdallah@skema.edu" className="google">
                    <i className="icon-envelope-open"></i>
                  </a>
                </li>
              </ul>
              <p>Copyright © 2021 - Nadia Abdallah Developpeur Full Stack MERN et experte VBA</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    //Footer Section End
    
);

export default Footer;