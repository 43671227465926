import React from "react";
import { Link } from "react-router-dom";
import Article from "../blog/Article";
import PropTypes from "prop-types";
// import "../blog/article.scss";

const ListArticle = ({ articles,type}) => {
   return (
      <div>

         
         {articles && type===0? 
         <div className="grid-container-blog">
            {articles.map(article => (
               <Link to={`/blog/${article.linkFormat}`} key={article.linkFormat}>
                  <Article article={article}/>
               </Link>
            ))}
         </div>: 
         <div className="grid-container-blog">
            {articles.map(article => (
               <Link to={`/dashboard/article/${article._id}`} key={article._id}>
                  <Article article={article}/>
               </Link>
            ))}
         </div>}
      </div>
   );
};

ListArticle.propTypes = {
   articles: PropTypes.array.isRequired
};

export default ListArticle;