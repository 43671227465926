import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {Container, Form} from "react-bootstrap";
import ListArticle from "./ListArticle";
// import "./dashboard.scss";

const Dashboard = ({ articles, auth,list_img }) => {
   const inputRef = useRef(null);
   const [search, setSearch] = useState("");
   const [display, setDisplay] = useState(false);

   const handleChange = e => {
      setSearch(inputRef.current.value.toLowerCase());
   };

   // setting no post found after waiting for a second
   useEffect(() => {
      setTimeout(() => {
         if (articles.length === 0) setDisplay(true);
      }, 1000);
   }, [articles]);

   return (
      <section className="services section-padding">
      <div className="container">
          <div className="row">
              <div className="col-md-10 col-lg-10 col-xs-10">
               
                  <Form>
                     <Form.Group controlId="searchBar">
                        <Form.Control
                           type="text"
                           placeholder="Chercher un article ..."
                           style={{ height: 40 }}
                           ref={inputRef}
                           onChange={handleChange}
                        />
                     </Form.Group>
                  </Form>   

              </div>

              <div className="col-lg-2 pb-5">
                  <Link to="/dashboard/create">
                     <button className="btn btn-common" id="submit" type="submit">
                        Ajouter un article
                     </button>
                  </Link>
               </div>

               {articles.length > 0 ? (
                  <Container className="container" >
                     <div className="row">
                        <div className="col-md-12 col-lg-12 col-xs-12">
                              <ListArticle
                                 articles={articles.filter(article =>
                                    article.title.toLowerCase().includes(search)
                                 )}
                                 list_img={list_img}
                                 type={1}
                              />
                        </div>
                     </div>
                  </Container>
                  ) : (
                  display && (
                     <Container
                        style={{ height: "50vh" }}
                        className="d-flex flex-column justify-content-center align-items-center"
                     >
                        {" "}
                        <p className="text-secondary h3">Pas d'articles trouvés ...</p>
                     </Container>
                  )
                  )
               }
           
           
          </div>
      </div>
          
          
      </section>
   );
};

Dashboard.propTypes = {
   auth: PropTypes.bool.isRequired,
   articles: PropTypes.array.isRequired
};

export default Dashboard;   

