import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Login from "../../components/auth/Login";
import Validate from "../../components/form/Validate";
import { connect } from "react-redux";
import { loginClient } from "../../actions/authActions";
import { clearErrors } from "../../actions/errorActions";

const LoginPage = ({ loginClient, auth, errors, history, clearErrors}) => {
   const [client, setClient] = useState({
      email: "",
      password: "",
      errors: {}
   });

   const [message, setMessage] = useState("");

   // clearing error incase user switches to login page while already having errors in signup page
   useEffect(() => {
      const unlisten = history.listen(() => clearErrors());
      if (localStorage.loginMessage) {
         setMessage(localStorage.loginMessage);
         localStorage.setItem("loginMessage", "");
      }
      return () => unlisten();
   }, [history, clearErrors]);

   useEffect(() => {
      if (auth.isAuthenticated) {
         history.push("/dashboard");
      }
      setClient(client => {
         return { ...client, errors };
      });
   }, [auth, errors, history]);

   const handleChange = e => {
      setClient({
         ...client,
         [e.target.name]: e.target.value
      });
   };

   const handleBlur = e => {
      const { name, value } = e.target;
      const error = { ...client.errors, ...Validate(name, value).errors };
      setClient({ ...client, errors: { ...error } });
   };

   const handleSubmit = e => {
      e.preventDefault();
      const { email, password } = client;
      loginClient({ email, password });
   };

   return (
      <div>

         <Login
            message={message}
            loading={auth.adminLoading}
            client={{ ...client }}
            onBlur={handleBlur}
            onChange={handleChange}
            onSubmit={handleSubmit}
         />
      </div>
   );
};

LoginPage.propTypes = {
   loginClient: PropTypes.func.isRequired,
   clearErrors: PropTypes.func.isRequired,
   auth: PropTypes.object.isRequired,
   errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
   auth: state.auth,
   errors: state.errors
});

export default connect(
   mapStateToProps,
   { loginClient, clearErrors }
)(LoginPage);