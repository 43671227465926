import React from "react";
import ListArticle from "../admin/ListArticle";
import PropTypes from "prop-types";

// import "./blogArticle.scss";

const BlogListArticle = ({articles}) => {
   return (
      <section className="services section-padding">
         <div className="container">
            <div className="row">
               <div className="col-md-12 col-lg-12 col-xs-12"> 
                  <ListArticle
                     articles={articles}
                     type={0}
                  />
                  </div>
            </div>
         </div>
      </section>
      
   );
};

BlogListArticle.propTypes = {
   articles: PropTypes.array.isRequired
};

export default BlogListArticle;