import {SET_MESSAGE_SUBMIT,SET_MESSAGE_ERROR} from "../actions/types";

export default function (state=false,action){
   switch(action.type){
      case SET_MESSAGE_SUBMIT:
         return !state;

      case SET_MESSAGE_ERROR:
         return state;

      default:
         return state;
   }
};