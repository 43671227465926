import React from 'react';

const Services = () => (
  <section id="services" className="services section-padding">

<h2 className="section-title wow flipInX" data-wow-delay="0.4s">Mes compétences</h2>
  <div className="container">
    <div className="row">
      {/* <!-- Services item --> */}
      <div className="col-md-6 col-lg-3 col-xs-12">
        <div className="services-item wow fadeInDown" data-wow-delay="0.3s">
          <div className="icon">
            <i className="icon-wrench"></i>
          </div>
          <div className="services-content">
            <h3><a href="#backEnd">Back-end</a></h3>
            <p>Compétences en javascript et python pour le développement de la partie back de vos projets</p>
          </div>
        </div>
      </div>
      {/* <!-- Services item --> */}
      <div className="col-md-6 col-lg-3 col-xs-12">
        <div className="services-item wow fadeInDown" data-wow-delay="0.6s">
          <div className="icon">
            <i className="icon-pencil"></i>
          </div>
          <div className="services-content">
            <h3><a href="#frontEnd">Front-end</a></h3>
            <p>Compétences en javascript, html/CSS pour la partie front de vos projets</p>
          </div>
        </div>
      </div>
      {/* <!-- Services item --> */}
      <div className="col-md-6 col-lg-3 col-xs-12">
        <div className="services-item wow fadeInDown" data-wow-delay="0.9s">
          <div className="icon">
            <i className="icon-docs"></i>
          </div>
          <div className="services-content">
            <h3><a href="#">VBA pour Excel</a></h3>
            <p>Accompagnement dans vos projets liés à Excel VBA, notamment dans l'élaboration de macro VBA</p>
          </div>
        </div>
      </div>
      {/* <!-- Services item --> */}
      <div className="col-md-6 col-lg-3 col-xs-12">
        <div className="services-item wow fadeInDown" data-wow-delay="1.2s">
          <div className="icon">
            <i className="icon-graph"></i>
          </div>
          <div className="services-content">
            <h3><a href="#donnees">Données</a></h3>
            <p>Accompagnement dans vos projets liés à l'analyse de données grâce à Python</p>
          </div>
        </div>
      </div>
    </div>
  </div>
{/* START - extra space */}
  <pre>           </pre>
{/* END - extra space */}

      <h1 className="section-title wow flipInX" data-wow-delay="0.4s">Technologies maîtrisées</h1>
      <div className="container">
        <div className="row">
          {/* <!-- Services item --> */}
          <div className="col-md-6 col-lg-3 col-xs-12 text-centered">
            <img className="skills-size skills-size-hover" src="img/skills/html.png" alt="HTLM logo"/>
              <h5>
                <strong>HTLM</strong>
              </h5>
          </div>
          {/* <!-- Services item --> */}
          <div className="col-md-6 col-lg-3 col-xs-12 text-centered">
            <img className="skills-size skills-size-hover" src="img/skills/css.png" alt="CSS logo"/>
              <h5>
                <strong>CSS</strong>
              </h5>
          </div>
          {/* <!-- Services item -->
          <div className="col-md-6 col-lg-3 col-xs-12 text-centered">
            <img className="skills-size skills-size-hover" src="img/skills/javascript.png" alt="Javascript logo"/>
              <h5>
                <strong>Javascript</strong>
              </h5>
          </div> */}
          {/* <!-- Services item --> */}
          <div className="col-md-6 col-lg-3 col-xs-12 text-centered">
            <img className="skills-size skills-size-hover" src="img/skills/react-js.png" alt="React JS logo"/>
              <h5>
                <strong>React JS</strong>
              </h5>
          </div>
          {/* <!-- Services item --> */}
          <div className="col-md-6 col-lg-3 col-xs-12 text-centered">
            <img className="skills-size skills-size-hover" src="img/skills/mongodb.png" alt="MongoDB logo"/>
              <h5>
                <strong>Mongo DB</strong>
              </h5>
          </div>
          {/* <!-- Services item --> */}
          <div className="col-md-6 col-lg-3 col-xs-12 text-centered">
            <img className="skills-size skills-size-hover" src="img/skills/nodejs.png" alt="Node JS logo"/>
              <h5>
                <strong>Node JS</strong>
              </h5>
          </div>
          {/* <!-- Services item --> */}
          <div className="col-md-6 col-lg-3 col-xs-12 text-centered">
            <img className="skills-size skills-size-hover" src="img/skills/next.png" alt="Next js logo"/>
              <div className="learning-tag blinker">En cours</div>
              <h5>
                <strong>Next JS</strong>
              </h5>
          </div>
          {/* <!-- Services item --> */}
          <div className="col-md-6 col-lg-3 col-xs-12 text-centered">
            <img className="skills-size skills-size-hover" src="img/skills/github.png" alt="GIT logo"/>
              <h5>
                <strong>GIT</strong>
              </h5>
          </div>
          {/* <!-- Services item --> */}
          <div className="col-md-6 col-lg-3 col-xs-12 text-centered">
            <img className="skills-size skills-size-hover" src="img/skills/anaplan.png" alt="Anaplan logo"/>
              <h5>
                <strong>Anaplan</strong>
              </h5>
          </div>
          {/* <!-- Services item --> */}
          <div className="col-md-6 col-lg-3 col-xs-12 text-centered">
            <img className="skills-size skills-size-hover" src="img/skills/excel.png" alt="Excel VBA logo"/>
              <h5>
                <strong>Excel VBA</strong>
              </h5>
          </div>
          {/* <!-- Services item --> */}
          <div className="col-md-6 col-lg-3 col-xs-12 text-centered">
            <img className="skills-size skills-size-hover" src="img/skills/python.png" alt="Python logo"/>
              <h5>
                <strong>Python</strong>
              </h5>
          </div>
          {/* <!-- Services item --> */}
          <div className="col-md-6 col-lg-3 col-xs-12 text-centered">
            <img className="skills-size skills-size-hover" src="img/skills/flasks.png" alt="Flask logo"/>
              <h5>
                <strong>Flask</strong>
              </h5>
          </div>
          {/* <!-- Services item --> */}
          <div className="col-md-6 col-lg-3 col-xs-12 text-centered">
            <img className="skills-size skills-size-hover" src="img/skills/panda.png" alt="Flask logo"/>
              <div className="learning-tag blinker">En cours</div>
              <h5>
                <strong>Pandas</strong>
              </h5>
          </div>
          {/* <!-- Services item --> */}
          <div className="col-md-6 col-lg-3 col-xs-12 text-centered">
            <img className="skills-size skills-size-hover" src="img/skills/telegram.png" alt="Flask logo"/>
              <h5>
                <strong>Telegram Bot</strong>
              </h5>
          </div>   
          {/* <!-- Services item --> */}
          <div className="col-md-6 col-lg-3 col-xs-12 text-centered">
            <img className="skills-size skills-size-hover" src="img/skills/sql.png" alt="SQL logo"/>
              <div className="learning-tag blinker">En cours</div>
              <h5>
                <strong>SQL</strong>
              </h5>
          </div>
       
        </div>
      </div>
    </section>  
);

export default Services;