import React from "react";
import Form from "react-bootstrap/Form";
import PropTypes from 'prop-types';

const Select = ({name,onChange,text,array,disabled}) => {
   return (
      <Form.Group controlId={text.module + name}>
         <Form.Label>{name}</Form.Label>
       
            <Form.Control as="select" name={name} onChange={onChange} disabled={disabled}>
               {array.map((a,key) => {return <option key={key}>{a}</option>})}
            </Form.Control>
         <Form.Control.Feedback type="invalid">
            {text.error}
         </Form.Control.Feedback>
      </Form.Group>
   );
};

Select.propTypes = {
   name: PropTypes.string.isRequired,
   text: PropTypes.object.isRequired
}

export default Select;